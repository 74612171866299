import { Injectable } from '@angular/core';
import { PlatformHelpersService } from '../platform.helpers.module/platform.helpers.service';


@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  private breakpoints = {
    mobile: 767,
    tablet: 1023,
    desktop: 1200,
  };

  currentBreakpoint: string = 'desktop'; // Default breakpoint

  constructor(
    private platformHelpersService: PlatformHelpersService
  ) {
    
  }

  getBreakpoint(): string {
    if (this.platformHelpersService.IsBrowserPlatform) {
      var width = window.innerWidth;

      for (const breakpoint in this.breakpoints) {
        if (width < this.breakpoints[breakpoint]) {
          this.currentBreakpoint = breakpoint;

          return breakpoint;
          break;
        }
      }

      return "desktop";
    }

    return "desktop"; // Add a default return statement
  }
}